import React, { Component, createRef } from "react";
import { navigate } from "gatsby";
import { extent, mean } from "d3-array";
import { forceCollide, forceSimulation, forceX, forceY } from "d3-force";
import { scaleLinear } from "d3-scale";
import { select } from "d3-selection";
import cn from "classnames";
import { withSize } from "react-sizeme";
import random from "lodash/random";
import map from "lodash/map";
import find from "lodash/find";
import forEach from "lodash/forEach";
import findIndex from "lodash/findIndex";

import Axis from "./Axis";
import AverageLine from "./AverageLine";
import HoverPopup from "./HoverPopup";
import Annotation from "./Annotation";
import EmptyMessage from "./EmptyMessage";

import { getIndustriesForYear } from "../../util/overview";
import { doesLabelFit } from "../../util/labels";
import { isMobile } from "../../util/mobile";

import styles from "./styles.module.scss";

const PADDING = 100;
const PADDING_MOBILE = 50;
const HEIGHT = 350;
const MOBILE_HEIGHT = 650;

class OverviewChart extends Component {
  constructor(props) {
    super(props);

    this.svgRef = createRef();
    this.labelsRef = createRef();

    this.state = {
      showHover: false,
      hovered: false,
    };

    this.ticked = this.ticked.bind(this);
  }

  isMobile() {
    const { size } = this.props;
    const { width } = size;

    return isMobile(width);
  }

  render() {
    const { flow, size } = this.props;
    const { showHover, hovered } = this.state;
    const { width } = size;

    this.calculateScales();

    const isMobile = this.isMobile();

    const height = isMobile ? MOBILE_HEIGHT : HEIGHT;

    return (
      <div className={styles.container} onTouchStart={this.cancelHover}>
        <div className={styles.svgContainer}>
          <svg
            className={cn(styles.svg, { [styles.show]: this.valid })}
            ref={this.svgRef}
            style={{ height }}
          />
          <AverageLine
            left={this.xScale(this.average)}
            top={this.yScale(this.average)}
            isMobile={isMobile}
            show={this.valid}
          />
        </div>

        <div className={styles.labels} ref={this.labelsRef} />

        <HoverPopup
          showHover={showHover}
          data={hovered}
          flow={flow}
          width={width}
          height={height}
          isMobile={isMobile}
        />

        <Axis
          xScale={this.xScale}
          yScale={this.yScale}
          flow={flow}
          isMobile={isMobile}
          show={this.valid}
        />

        <Annotation show={this.valid} />

        <EmptyMessage show={!this.valid} />
      </div>
    );
  }

  cancelHover = () => {
    this.setState({ showHover: false });
  };

  componentDidMount() {
    this.createChart();
  }

  componentDidUpdate({ flow, timeframe }) {
    if (
      flow !== this.props.flow ||
      (timeframe !== this.props.timeframe && this.state.showHover)
    ) {
      this.cancelHover();
    }

    this.createChart();
  }

  calculateScales() {
    const { flow, size, timeframe } = this.props;
    const { width } = size;
    const isMobile = this.isMobile();

    this.industries = getIndustriesForYear(timeframe);

    this.average = mean(
      this.industries,
      (dataItem) => dataItem[`${flow}_perc`]
    );

    const padding = isMobile ? PADDING_MOBILE : PADDING;

    if (!this.nodes) {
      this.nodes = map(this.industries, (dataItem) => ({
        ...dataItem,
        y: HEIGHT / 2,
        x: random(padding, width - padding),
      }));
    }

    this.nodes = map(this.nodes, (dataItem) => {
      const { industry } = dataItem;

      const newItem = find(this.industries, { industry });

      return {
        ...dataItem,
        ...newItem,
        valid: newItem[`${flow}_perc`] !== 0,
      };
    });

    this.xScale = scaleLinear()
      .domain(
        extent(this.nodes, (data) => (data.valid ? data[`${flow}_perc`] : null))
      )
      .nice()
      .range([padding, width - padding]);

    this.yScale = scaleLinear()
      .domain(
        extent(this.nodes, (data) => (data.valid ? data[`${flow}_perc`] : null))
      )
      .nice()
      .range([MOBILE_HEIGHT - padding * 3, padding * 2]);

    this.rScale = scaleLinear()
      .domain(extent(this.nodes, ({ total }) => total))
      .range([7, 50]);

    this.nodes = map(this.nodes, (dataItem) => ({
      ...dataItem,
      radius: this.rScale(dataItem.total),
      xPosition: dataItem.valid
        ? this.xScale(dataItem[`${flow}_perc`])
        : width / 2,
      yPosition: dataItem.valid
        ? this.yScale(dataItem[`${flow}_perc`])
        : MOBILE_HEIGHT / 2,
      showLabel:
        dataItem.valid &&
        doesLabelFit(dataItem.industry, this.rScale(dataItem.total)),
    }));

    this.valid = findIndex(this.nodes, { valid: true }) !== -1;
  }

  createChart() {
    const { size } = this.props;

    const { width } = size;

    if (this.simulation) {
      this.simulation.stop();
    }

    this.simulation = forceSimulation(this.nodes)
      .force(
        "collision",
        forceCollide().radius(({ radius }) => radius + 2)
      )
      .alphaDecay(0.01)
      .on("tick", this.ticked);

    if (this.isMobile()) {
      this.simulation
        .force(
          "x",
          forceX()
            .x(({ to_perc }) => (to_perc === 0 ? -100 : width / 2))
            .strength(0.06)
        )
        .force(
          "y",
          forceY()
            .y(({ yPosition }) => yPosition)
            .strength(0.06)
        );
    } else {
      this.simulation
        .force(
          "x",
          forceX()
            .x(({ xPosition }) => xPosition)
            .strength(0.06)
        )
        .force(
          "y",
          forceY()
            .y(({ to_perc }) => (to_perc === 0 ? -100 : HEIGHT / 2))
            .strength(0.06)
        );
    }
  }

  ticked() {
    const { flow, timeframe, size } = this.props;
    const { showHover, hovered } = this.state;

    const { width } = size;

    const isMobile = this.isMobile();

    const circles = select(this.svgRef.current)
      .selectAll("circle")
      .data(this.nodes, ({ industry }) => industry);

    const rects = [];

    circles
      .enter()
      .append("circle")
      .attr("stroke-width", 0)
      .merge(circles)
      .attr("r", ({ radius }) => radius)
      .attr("class", cn(styles.circle, `fill-${flow}`))
      .attr("cx", ({ x }) => x)
      .attr("cy", ({ y }) => y)
      .attr("data-value", (data) => data[`${flow}_perc`])
      .style("opacity", ({ industry }) =>
        showHover === false ? 1 : hovered.industry === industry ? 1 : 0.75
      )
      .on(
        "touchstart",
        (event, data) => {
          event.preventDefault();
          event.stopPropagation();

          const { industry } = data;

          if (showHover && hovered.industry === industry) {
            navigate("/industry/", { state: { industry, timeframe } });
          }

          this.setState({ showHover: true, hovered: data });
        },
        { passive: false }
      )
      .on("click", (event, { industry }) => {
        navigate("/industry/", { state: { industry, timeframe } });
      })
      .on("mouseenter", (event, data) => {
        this.setState({ showHover: true, hovered: data });
      })
      .on("mouseleave", (event) => {
        this.setState({ showHover: false });
      })
      .each(function () {
        const item = select(this);
        const rect = item.node().getBoundingClientRect();
        rects.push(rect);
      });

    const labels = select(this.labelsRef.current)
      .selectAll("span")
      .data(this.nodes, ({ industry }) => industry);

    labels
      .enter()
      .append("span")
      .html(({ industry }) => industry)
      .merge(labels)
      .attr("class", styles.label)
      .style("top", ({ y }) => `${y}px`)
      .style("left", ({ x }) => `${x}px`)
      .style("width", ({ radius }) => `${radius * 2}px`)
      .style("opacity", ({ showLabel }) => (showLabel ? 1 : 0));

    const outerLabels = select(this.labelsRef.current)
      .selectAll("div")
      .data(this.nodes, ({ industry }) => industry);

    outerLabels
      .enter()
      .append("div")
      .html(({ industry }) => industry)
      .merge(outerLabels)
      .attr("class", ({ x, y, radius }) =>
        cn(styles.label, styles.outerLabel, {
          [styles.mobileLabel]: isMobile,
          [styles.topLabel]: isMobile ? x > width / 2 : y < HEIGHT / 2,
          [styles.bottomLabel]: isMobile ? x < width / 2 : y > HEIGHT / 2,
        })
      )
      .style(
        "top",
        ({ y, radius }) =>
          `${y < HEIGHT / 2 ? y - radius - 10 : y + radius + 10}px`
      )
      .style("left", ({ x }) => `${x}px`)
      .each(function ({ showLabel, to_perc, industry }) {
        const item = select(this);
        if (showLabel || to_perc === 0) {
          item.style("opacity", 0);
        } else {
          const rect = item.node().getBoundingClientRect();
          let collision = false;

          forEach(rects, ({ left, right, top, bottom }) => {
            if (
              !(
                rect.left > right ||
                rect.right < left ||
                rect.bottom < top ||
                rect.top > bottom
              )
            ) {
              collision = true;
              return false;
            }
          });

          if (!collision) {
            rects.push(rect);
          }

          item.style("opacity", collision ? 0 : 1);
        }
      });
  }
}

export default withSize()(OverviewChart);
