import React, { useState, useEffect } from "react";
import numeral from "numeral";
import cn from "classnames";
import Bar from "../../Bar";
import { getFlowLabel } from "../../../util/flow";

import styles from "./styles.module.scss";

const HoverPopup = ({ showHover, data, flow, width, height, isMobile }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showHover);
  }, [showHover]);

  let top = 0;
  let left = 0;
  let flip = isMobile ? data.y > height / 2 : data.x > width / 2;

  if (data) {
    if (isMobile) {
      top = data.y + (flip ? -data.radius : data.radius);
      left = data.x;
    } else {
      top = data.y - data.radius - 5;
      left = data.x + (flip ? -data.radius - 5 : data.radius + 5);
    }
  }

  return (
    <div
      className={cn(styles.container, {
        [styles.show]: show,
        [styles.flip]: flip,
        [styles.mobile]: isMobile,
      })}
      style={{ top, left }}
    >
      <div className={styles.header}>{data.industry}</div>
      <div className={styles.body}>
        <div className={styles.row}>
          <strong className="color-black">
            {numeral(data.total).format("0a")}
          </strong>{" "}
          job changes
        </div>
        <div className={styles.row}>
          <strong className={`color-${flow}`}>
            {Math.round(data[`${flow}_perc`])}%
          </strong>{" "}
          {getFlowLabel(flow)}
        </div>

        <Bar
          bgClassName={`bg-${flow} faded`}
          className={`bg-${flow}`}
          value={data[`${flow}_perc`]}
          height={5}
        />
      </div>
    </div>
  );
};

export default HoverPopup;
