import React from "react";
import cn from "classnames";
import styles from "./styles.module.scss";
import { getFlowLabel } from "../../../util/flow";

const Axis = ({ xScale, yScale, flow, isMobile, show }) => (
  <div className={cn(styles.container, { [styles.show]: show })}>
    {isMobile ? (
      <div className={styles.yAxis}>
        {yScale.ticks(8).map((tick, index) => (
          <div
            key={index}
            className={styles.tick}
            style={{ top: yScale(tick) }}
          >
            <div className={styles.marker} />
            <span className={styles.tickLabel}>{tick}%</span>
          </div>
        ))}
      </div>
    ) : (
      <div className={styles.xAxis}>
        {xScale.ticks(8).map((tick, index) => (
          <div
            key={index}
            className={styles.tick}
            style={{ left: xScale(tick) }}
          >
            <div className={styles.marker} />
            <span className={styles.tickLabel}>{tick}%</span>
          </div>
        ))}
      </div>
    )}

    <p
      className={cn(styles.label, "color-grey", {
        [styles.mobile]: isMobile,
      })}
    >
      % of job changes within an industry that are people{" "}
      <strong>{getFlowLabel(flow)}</strong>
    </p>
  </div>
);

export default Axis;
