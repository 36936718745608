import React from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

const AverageLine = ({ left, top, isMobile, show }) => {
  const style = {};

  if (isMobile) {
    style.top = top;
  } else {
    style.left = left;
  }

  return (
    <div
      className={cn(styles.average, {
        [styles.mobile]: isMobile,
        [styles.show]: show,
      })}
      style={style}
    >
      <span className={styles.averageText}>Avg.</span>
    </div>
  );
};

export default AverageLine;
