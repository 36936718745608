import React, { useState } from "react";
import { Link } from "gatsby";
import numeral from "numeral";
import CountUp from "react-countup";

import Box from "../components/Box";
import Bar from "../components/Bar";
import Header from "../components/Header";
import Layout from "../components/Layout";
import OverviewChart from "../components/OverviewChart";
import Select from "../components/Select";
import Footer from "../components/Footer";

import {
  getYears,
  getNumberOfIndustries,
  getNumberOfJobChanges,
  getChangeFromYear,
} from "../util/overview";

const years = getYears();

const IndexPage = () => {
  const [timeframe, setTimeframe] = useState(years[0]);
  const [flow, setFlow] = useState("to");

  const numberOfIndustries = getNumberOfIndustries(timeframe);
  let numberOfJobChanges = getNumberOfJobChanges(timeframe);

  const { changed_industry_perc, same_industry_perc } =
    getChangeFromYear(timeframe);

  const formattedJobChanges = numeral(numberOfJobChanges).format(
    "0.0 a",
    Math.round
  );

  return (
    <Layout>
      <Header tracker>
        <h1>Career Change Tracker</h1>
        <p>
          Over half of people who change jobs also move into a new industry. But
          where do they go? Use our Career Change Tracker below to compare which
          industries see the most people joining, leaving, or moving internally.
          You can also explore the impact of job changes on{" "}
          <Link className="white" to="/industry/">
            your industry
          </Link>
          , and what this means for the{" "}
          <Link className="white" to="/insights/">
            wider job market
          </Link>
          .
        </p>

        <p>
          <label htmlFor="timeframe_select" className="type-30 me-4">
            Here are the job changes we saw in:
          </label>

          <Select
            id="timeframe_select"
            onChange={(event) => setTimeframe(event.target.value)}
            value={timeframe}
            options={years.map((year) => ({ label: year, value: year }))}
          />
        </p>

        <div className="grid">
          <Box className="g-col-12 g-col-md-3">
            <span className="fw-bold d-block type-34">
              <CountUp end={numberOfIndustries} duration={1} preserveValue />
            </span>
            <span className="type-20">Industries</span>
          </Box>
          <Box className="g-col-12 g-col-md-3">
            <span className="fw-bold d-block type-34">
              {formattedJobChanges}
            </span>
            <span className="type-20">Job changes</span>
          </Box>
          <Box className="g-col-12 g-col-md-6">
            <div className="row mb-2">
              <div className="col-6">
                <span className="fw-bold d-block type-34 color-changed">
                  <CountUp
                    end={changed_industry_perc}
                    duration={1}
                    preserveValue
                    suffix="%"
                  />
                </span>
                <span className="type-20">
                  of workers changed
                  <br />
                  industries
                </span>
              </div>
              <div className="col-6 text-end">
                <span className="fw-bold d-block type-34 color-retained">
                  <CountUp
                    end={same_industry_perc}
                    duration={1}
                    preserveValue
                    suffix="%"
                  />
                </span>
                <span className="type-20">
                  of workers changed jobs within their industry
                </span>
              </div>
            </div>

            <Bar
              className="bg-changed"
              bgClassName="bg-retained"
              value={changed_industry_perc}
              flat
            />
          </Box>
        </div>

        <Box className="mt-4">
          <span>
            <label htmlFor="flow_select" className="type-24 fw-bold me-4">
              Which industries saw the most workers
            </label>

            <Select
              id="flow_select"
              onChange={(event) => setFlow(event.target.value)}
              value={flow}
              smaller
              options={[
                { value: "to", label: "joining" },
                { value: "retained", label: "moving internally" },
                { value: "from", label: "leaving" },
              ]}
            />
          </span>

          <p className="subtitle">
            Hover over the bubbles to find your industry. Click to find out
            more.
          </p>
        </Box>
      </Header>

      <div className="container">
        <OverviewChart timeframe={timeframe} flow={flow} />
      </div>

      <Footer />
    </Layout>
  );
};

export default IndexPage;
