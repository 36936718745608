import React from "react";
import cn from "classnames";

import styles from "./styles.module.scss";

const EmptyMessage = ({ show }) => (
  <div className={cn(styles.container, { [styles.show]: show })}>
    <span className={styles.text}>
      Sorry, we don’t have data available for this view yet (because of the time
      it takes people to update their online profiles and CVs after changing
      jobs). Please check back in a few months’ time as we update our data
      quarterly.
    </span>
  </div>
);

export default EmptyMessage;
