import React from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

const Annotation = ({ show }) => {
  return (
    <div className={cn(styles.container, { [styles.show]: show })}>
      <span className={styles.label}>
        Bubble size represents all job changes for that industry
      </span>
    </div>
  );
};

export default Annotation;
