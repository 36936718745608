import max from "lodash/max";

export const doesLabelFit = (text, radius) => {
  const canvas = document.createElement("canvas"),
    context = canvas.getContext("2d");

  context.font = "14px TotalSans";

  const words = text.split(" ");

  const widths = words.map((word) => {
    return context.measureText(word).width;
  });

  const longestWord = max(widths);

  return longestWord < radius * 2 - 5;
};
